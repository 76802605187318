.footer {
  background-color: #353740;
  overflow: visible;
}
.footer .scroll-top-container {
  display: flex;
  justify-content: center;
}

.footer .scroll-top {
  width: 50px;
  height: 50px;
  background-color: #353740;
  color: #fff;
  font-size: 21px;
  line-height: 50px;
  border-radius: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.scroll-top:hover {
  background-color: #0f9a9e;
}

.footer span {
  color: white;
  font-size: 18px;
}

.footer .social-icon {
  display: inline-block;
  font-size: 25px;
  margin: 10px 25px;
}

.footer .social-icon-img:hover {
  color: #0f9a9e;
}
