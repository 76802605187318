.about {
  background-color: black;
  color: white;
  overflow: hidden;
}

#about .section-title {
  font-size: 32px;
  color: #f06000;
  margin-top: 56px;
  margin-bottom: 24px;
  font-weight: bold;
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 56px;
}

.profile-img {
  min-width: 120px;
  height: 120px;
  border-radius: 50%;
  background-image: url("/src/assets/images/portrait.jpeg");
  background-size: cover;
  transform: rotate(-6deg);
  border-radius: 50%;
  margin: 15px 16px 0 32px;
  float: left;
}

.profile-desc {
  text-align: justify;
  max-width: 600px;
  max-height: 100px;
  margin: 15px 25px 15px 5px;
}

.skill-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-bars {
  list-style: none;
  margin: 3rem 0 3rem;
}

.skill-bars li {
  height: 0.6rem;
  background: #282828;
  width: 100%;
  margin-bottom: 64px;
  padding: 0;
  position: relative;
}

.skill-bars li strong {
  position: absolute;
  left: 0;
  top: -36px;
  color: #a1a1a1;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 16px;
  line-height: 2.4rem;
}

.skill-bars li .progress {
  background: #a1a1a1;
  height: 100%;
}

.skill-bar-percent {
  position: absolute;
  right: 0;
  top: -24px;
  display: block;
  color: white;
  font-size: 16px;
  line-height: 1;
}

.skill-bars li .percent50 {
  width: 50%;
}
.skill-bars li .percent55 {
  width: 55%;
}
.skill-bars li .percent60 {
  width: 60%;
}
.skill-bars li .percent65 {
  width: 65%;
}
.skill-bars li .percent70 {
  width: 70%;
}
.skill-bars li .percent75 {
  width: 75%;
}
.skill-bars li .percent80 {
  width: 80%;
}
.skill-bars li .percent85 {
  width: 85%;
}
.skill-bars li .percent90 {
  width: 90%;
}
.skill-bars li .percent95 {
  width: 95%;
}
.skill-bars li .percent100 {
  width: 100%;
}

.button-section {
  text-align: center;
  padding: 50px 0px;
  margin-bottom: 40px;
}

.button-section .button {
  width: 250px;
}

#about .button {
  background-color: #11abb0;
  color: #fff;
  padding: 12px 20px;
  text-decoration: none;
  font-size: 16px/30px;
  cursor: pointer;
  border: none;
  height: auto;
}

#about .button:hover {
  background-color: #474747;
}

@media screen and (max-width: 650px) {
  .profile {
    flex-direction: column;
  }

  .profile-img {
    float: none;
    display: inline-block;
    margin-bottom: 10px;
  }
  .profile-desc {
    padding-left: 0;
    text-align: center;
    margin: 15px 25px 15px 25px;
    max-height: none;
  }
}
