@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&family=Roboto:wght@500&display=swap");
.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

nav-bar {
  background-color: #353740;
}

.pointer {
  cursor: pointer;
}
a.nav-link.pointer {
  font-weight: bold;
  color: white;
}
a.nav-link.pointer.active {
  color: #dd673d;
}

li a:hover {
  color: #11abb0;
}

.nav-content {
  align-items: center;
  flex: 1;
  justify-content: center;
}

.tmp {
  background-color: red;
}
