#portfolio {
  background-color: #f0f3f0;
}

#portfolio .section-title {
  font-size: 32px;
  color: #f06000;
  margin-top: 56px;
  margin-bottom: 48px;
  font-weight: bold;
}

#portfolio .portfolio-container {
  justify-content: center;
  margin: 0px 0px 40px;
}

#portfolio .portfolio-item {
  overflow: hidden;
  padding: 0px;
}

#portfolio .item-img-container {
  cursor: pointer;
}

#portfolio .item-img-container:hover {
  opacity: 0.5;
}

#portfolio .item-img {
  width: 100%;
  height: 250px;
  display: block;
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

#portfolio .item-title {
  color: white;
}

#portfolio .item-desc {
  color: #959595;
}

.portfolio-item {
  display: inline-block;
  margin: 0px 0px 40px;
  background-color: #3c3c3c;
  border-radius: 5px;
  top: 0;
}

#portfolio .item-content {
  margin: 0 auto;
  padding: 25px;
  max-width: 400px;
  text-align: center;
}

#portfolio .tag {
  padding: 2px 7px;
  display: inline-block;
  font-size: 16px;
  background-color: #3c3c3c;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 20px;
  border-style: solid;
  border-color: #d9d9d9;
  color: #d9d9d9;
}
