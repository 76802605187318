#contact {
  background-image: url("/src/assets/images/map.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 75vh;
  overflow: hidden;
}
#contact .section-title {
  font-size: 32px;
  color: #f06000;
  margin-top: 56px;
  margin-bottom: 0px;
  font-weight: bold;
}

#contact .social-item {
  display: inline-block;
}

#contact .desc {
  font-size: 24px;
  color: #696969;
}

#contact .social {
  margin: 40px 0px;
  padding: 0;
  font-size: 35px;
  list-style: none outside;
}

.contact-form {
  text-align: start;
}

.contact-form span {
  position: relative;
  left: 5%;
  font-size: 20px;
  color: #696969;
}

#contact .section-intro h1 {
  color: white;
}
#contact .section-intro p {
  color: rgba(255, 255, 255, 0.7);
}

.contact-info {
  margin: 4.8rem auto 0;
  font-family: "poppins-regular", sans-serif;
  font-size: 1.5rem;
  text-align: center;
}
.contact-info .collapse {
  padding: 0;
}
.contact-info .icon {
  margin-bottom: 2.1rem;
}
.contact-info .icon i {
  font-size: 4.2rem;
  color: #ffffff;
}
.contact-info h5 {
  color: #f06000;
}
