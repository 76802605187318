#home {
  background-image: url("/src/assets/images/hero.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  height: 100vh;
}

#home .social {
  margin: 40px 0px;
  padding: 0;
  font-size: 35px;
  list-style: none outside;
}

#home .social-icon {
  display: inline-block;
  margin: 10px 25px;
}

#home .social-icon-img:hover {
  color: #0f9a9e;
}

#home .hero-highline {
  font-weight: bold;
  font-size: 60px;
}

#home .pic-credit {
  position: absolute;
  text-align: end;
  top: 95vh;
  font-size: 12px;
}
