.timeline {
  background-color: #f0f3f0;
}
.timeline .section-title {
  font-size: 32px;
  color: #f06000;
  margin-top: 56px;
  font-weight: bold;
}

.timeline-container {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 4rem;
}

.timeline-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 35.5%;
  width: 2px;
  height: 100%;
  background: #ccd1d9;
  z-index: 1;
}

.timeline-block {
  position: relative;
  padding-top: 40px;
  padding-bottom: 10px;
}

.timeline-header {
  float: left;
  width: 35%;
  padding-right: 50px;
  text-align: right;
}

.timeline-leftTitle {
  font-size: 24px;
  color: #313131;
}

.timeline-icon {
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: #313131;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  left: 35.5%;
  margin-left: -20px;
  z-index: 1;
}

.timeline-date {
  color: #888888;
  font: 16px;
}

.timeline-content {
  margin-left: 35%;
  padding-left: 60px;
  border-width: 1px;
  text-align: left;
}

.timeline-subtitle {
  position: relative;
  padding-bottom: 8px;
}

.timeline-subtitle::after {
  content: "";
  display: block;
  height: 3px;
  width: 60px;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  bottom: 0;
}

.timeline-subtitle {
  color: #313131;
}

.timeline-desc {
  font-size: 16px;
  color: rgb(131, 140, 149);
  margin-bottom: 8px;
}

.timeline-content a {
  color: #11abb0;
  font-size: 24px;
  text-decoration: none;
}

.timeline-content a:hover {
  color: black;
}

.timeline .tag {
  display: inline-block;
  font-size: 16px;
  background-color: #959595;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 15px;
  border-radius: 20px;
  color: white;
}

/*Responsive timeline*/
@media only screen and (max-width: 768px) {
  .timeline-container:before {
    left: 4.7rem;
  }
  .timeline-icon {
    left: 4rem;
  }
  .timeline-header {
    float: none;
    width: auto;
    padding-right: 15px;
    text-align: left;
  }
  .timeline-content {
    margin: 0;
  }
  .timeline-content h4 {
    padding-bottom: 0;
    margin-bottom: 0.6rem;
  }
  .timeline-content h4::after {
    bottom: auto;
    top: 0;
  }
  .timeline-header,
  .timeline-content {
    padding-left: 7rem;
  }
}
