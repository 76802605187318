#notes {
  background-color: #f0f3f0;
}

#notes .section-title {
  font-size: 32px;
  color: #f06000;
  margin-top: 56px;
  margin-bottom: 48px;
  font-weight: bold;
}

#notes .notes-container {
  justify-content: center;
  margin: 0px 0px 100px;
}

#notes .subject-container {
  display: inline-block;
  padding: 0px;
  background-color: #3c3c3c;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  top: 0;
  border-right: 1px dashed #d9d9d9;
}

#notes .subject-item {
  padding: 20px 20px;
  font-size: 24px;
  font-weight: bold;
  color: #d9d9d9;
  text-align: center;
  cursor: pointer;
}

#notes .subject-item:hover {
  color: #db9464;
}

#notes .active-subject {
  color: #f06000;
}

#notes .file-container {
  display: inline-block;
  background-color: #3c3c3c;
  padding: 10px 0px 0px 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  top: 0;
}

.file-item {
  padding: 5px 20px;
  text-align: center;
}

#notes .file-link {
  color: #d9d9d9;
}

#notes .file-link:hover {
  color: #db9464;
}

#notes .disclaimer {
  margin-top: 20px;
  color: #919191;
  text-align: left;
  font-size: 12px;
  font-style: italic;
}
